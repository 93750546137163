<template>
  <div class="client-info box">
    <div class="columns">
      <div class="column">
        <h6>Фамилия:</h6>
        <p>{{ clientData.lastName }}</p>
      </div>
      <div class="column">
        <h6>Имя:</h6>
        <p>{{ clientData.firstName }}</p>
      </div>
      <div class="column">
        <h6>Отчество:</h6>
        <p>{{ clientData.patronymic }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h6>Пол:</h6>
        <p>{{ Number(clientData.gender) ? 'Женский' : 'Мужской' }}</p>
      </div>
      <div class="column">
        <h6>Телефон:</h6>
        <p>{{ clientData.phone }}</p>
      </div>
      <div class="column">
        <h6>Email:</h6>
        <p>{{ clientData.email }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h6>Дата рождения:</h6>
        <p>{{ clientData.birthday }}</p>
      </div>
      <div class="column">
        <h6>Место рождения:</h6>
        <p>{{ clientData.birthCity }}</p>
      </div>
      <div class="column">
        <h6>Серия паспорта:</h6>
        <p>{{ clientData.passportSeries }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h6>Номер паспорта:</h6>
        <p>{{ clientData.passportNumber }}</p>
      </div>
      <div class="column">
        <h6>Код подразделения:</h6>
        <p>{{ clientData.passportIssueCode }}</p>
      </div>
      <div class="column">
        <h6>Кем выдан:</h6>
        <p>{{ clientData.passportIssue }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h6>Дата выдачи:</h6>
        <p>{{ clientData.passportDate }}</p>
      </div>
      <div class="column">
        <h6>Регион:</h6>
        <p>{{ clientData.addrRegion }}</p>
      </div>
      <div class="column">
        <h6>Город:</h6>
        <p>{{ clientData.addrCity }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h6>Улица:</h6>
        <p>{{ clientData.addrStreet }}</p>
      </div>
      <div class="column">
        <h6>Дом:</h6>
        <p>{{ clientData.addrHouse }}</p>
      </div>
      <div class="column">
        <h6>Квартира:</h6>
        <p>{{ clientData.addrApartment }}</p>
      </div>
    </div>
    <GroupButtons :id="clientData.id" :type="'physical'" />
  </div>
</template>

<script>
import GroupButtons from './common/GroupButtons';

export default {
  name: 'Physical',
  props: ['clientData'],
  components: {
    GroupButtons,
  },
};
</script>
